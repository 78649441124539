<template>
  <div class="v-lines-container">
    <div class="container">
      <div class="vertical-lines">
        <span
          v-for="(_, i) in new Array(5)"
          :key="i"
          :style="`border-left-color: ${linesColor}`"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { layoutState } from "@/components/layouts/layoutStyles";

export default {
  name: "VerticalLines",
  data() {
    return {
      layoutState
    };
  },
  props: {
    color: {
      type: String,
      default: ""
    }
  },
  computed: {
    linesColor() {
      if (this.color) {
        return this.color;
      }
      return layoutState.layoutStyle.line;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/imports";

.v-lines-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  bottom: 0;

  .container {
    height: 100%;
  }
}

.vertical-lines {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  span {
    display: none;
    border-left: 1px solid $colorVerticalLines;
    height: 100%;
    width: 1px;

    &:nth-child(1),
    &:nth-child(2) {
      display: block;
    }

    @include breakpoint(md) {
      &:nth-child(3) {
        display: block;
      }
    }
    @include breakpoint(lg) {
      &:nth-child(4),
      &:nth-child(5) {
        display: block;
      }
    }
  }
}
</style>
