<template>
  <div>
    <main-menu-button />
    <main-menu-overlay v-if="showOverlay" />
  </div>
</template>

<script>
import MainMenuButton from "@/components/layouts/MainMenu/MenuButton";
import MainMenuOverlay from "@/components/layouts/MainMenu/MenuOverlay";
import { layoutState } from "@/components/layouts/layoutStyles";

export default {
  name: "MainMenu",
  components: {
    MainMenuOverlay,
    MainMenuButton
  },
  data() {
    return {
      isOpen: false
    };
  },
  computed: {
    showOverlay() {
      return layoutState.showMainMenuOverlay;
    },
    isOverlay: {
      get() {
        return layoutState.showMainMenuOverlay;
      },
      set(value) {
        layoutState.showMainMenuOverlay = value;
      }
    }
  },
  mounted() {
    layoutState.showMainMenuOverlay = false;
  },
  methods: {
    closeWindow(event) {
      if (this.isOverlay) {
        const ESC = 27;
        if (["Escape", "Esc"].includes(event.key) || event.keyCode === ESC) {
          this.isOverlay = false;
        }
      }
    }
  },
  watch: {
    isOverlay(val) {
      if (val) {
        window.addEventListener("keyup", this.closeWindow);
      } else {
        window.removeEventListener("keyup", this.closeWindow);
      }
    }
  }
};
</script>

<style scoped></style>
