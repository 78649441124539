<template>
  <div data-view>
    <section
      ref="content"
      class="content position-relative"
      :style="styleContent"
    >
      <VerticalLines />
      <router-view></router-view>
    </section>
    <section
      ref="header"
      class="header"
      :style="styleHeader"
      :class="layoutState.hideHeader && 'd-none'"
    >
      <VerticalLines />
      <div class="container">
        <div
          class="header-content d-flex position-relative flex-row justify-content-center align-items-center"
        >
          <router-link
            :to="{ name: 'startseite' }"
            v-if="logo"
            class="logo d-flex pr-1 position-absolute"
          >
            <component
              v-if="logo"
              :is="logo.is"
              v-bind="logo.props"
              v-on="logo.events"
            />
          </router-link>
          <router-link
            v-if="layoutState.slogan"
            :to="{ name: 'startseite' }"
            class="slogan d-block"
            v-html="layoutState.slogan"
          />
          <div class="spacer" />
          <a
            v-if="layoutState.showPhone"
            href="tel:+49631205755221"
            class="phone"
            >{{ $t("phone") }}</a
          >
          <MainMenu />
        </div>
      </div>
    </section>
    <LayoutFooter />
  </div>
</template>

<script>
import VerticalLines from "@/components/layouts/VerticalLines";
import { layoutState } from "@/components/layouts/layoutStyles";
import MainMenu from "@/components/layouts/MainMenu/";
import LayoutFooter from "@/components/layouts/LayoutFooter";

export default {
  name: "Layout",
  components: {
    LayoutFooter,
    MainMenu,
    VerticalLines
  },
  data() {
    return {
      isFixedHeader: false
    };
  },
  computed: {
    layoutState() {
      return layoutState;
    },
    logo() {
      return layoutState.logo;
    },
    styleContent() {
      return {
        backgroundColor: this.layoutState.layoutStyle.backgroundLayout,
        color: this.layoutState.layoutStyle.color
      };
    },

    styleHeader() {
      if (this.layoutState.routeTransition) {
        return {
          backgroundColor: "transparent"
        };
      }
      return {
        backgroundColor: this.layoutState.layoutStyle.backgroundHeader
      };
    }
  },
  i18n: {
    messages: {
      de: {
        phone: "0631 205 755 221"
      },
      en: {
        phone: "+49 631 205 755 221"
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/imports";

$headerHeight: 100px;
$footerHeight: 65px;
$zIndexHeader: 2;
$zIndexContent: 1;

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.header {
  background: transparent;
  z-index: $zIndexContent;
  height: $headerHeight;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  .header-content {
    height: 100px;
  }

  .logo {
    z-index: 2;
    max-width: 250px;
    left: 0;
    @include breakpoint(md) {
      max-width: 300px;
    }
  }

  .slogan {
    max-width: 145px;
    color: $main-color;
    text-transform: uppercase;
    font-size: $logoFontSize;
    font-weight: $font-weight-bold;
    line-height: $line-height-logo;
    letter-spacing: 1.9px;
    margin-left: 65px;
    z-index: 2;
  }

  .phone {
    display: none;
    left: 50%;
    font-size: $h4-font-size;
    @include breakpoint(md) {
      display: block;
      position: absolute;
    }
    @include breakpoint(lg) {
      font-weight: $font-weight-medium;
      left: 75%;
    }
  }
}

.header-main {
  background-color: transparent;
}

.mobile-hidden {
  display: none;
  @include breakpoint(md) {
    display: block;
  }
}

.content {
  color: $text-color-dark;
  z-index: $zIndexContent;
  min-height: calc(100vh - #{$headerHeight} - #{$footerHeight});

  address {
    font-size: $font-size-base;
  }

  ul {
    padding: 0;
    list-style-position: inside;
  }

  p {
    font-size: $font-size-base;
    line-height: $line-height-base;
  }
}
</style>
