<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 2107.000000 147.000000"
    preserveAspectRatio=""
  >
    <g
      ref="container"
      class="svg-container"
      transform="translate(0.000000,147.000000) scale(0.100000,-0.100000)"
      :fill="color"
      stroke="none"
    >
      <path
        d="M0 735 l0 -715 210 0 210 0 0 517 c0 284 3 513 7 509 4 -4 130 -235
279 -514 l272 -507 169 -3 168 -2 149 277 c82 153 208 386 280 518 l131 240 3
-517 2 -518 210 0 210 0 0 715 0 715 -318 0 -318 0 -118 -227 c-302 -580 -390
-743 -396 -736 -7 8 -383 712 -470 881 l-42 82 -319 0 -319 0 0 -715z"
      />
      <path
        d="M2600 735 l0 -715 785 0 785 0 0 165 0 165 -570 0 -570 0 0 120 0
120 525 0 525 0 0 150 0 150 -502 0 c-277 0 -513 3 -525 6 -22 6 -23 11 -23
120 l0 114 555 0 555 0 0 160 0 160 -770 0 -770 0 0 -715z"
      />
      <path
        d="M4380 735 l0 -715 573 0 c924 1 1067 19 1201 153 47 46 58 65 80 137
38 119 50 259 42 505 -8 299 -37 407 -132 495 -134 123 -277 140 -1186 140
l-578 0 0 -715z m1175 375 c139 -12 177 -23 224 -66 56 -50 66 -96 66 -304 0
-205 -9 -248 -64 -300 -71 -67 -165 -79 -638 -80 l-323 0 0 380 0 380 313 0
c171 0 362 -5 422 -10z"
      />
      <path
        d="M6540 735 l0 -715 215 0 215 0 0 715 0 715 -215 0 -215 0 0 -715z"
      />
      <path
        d="M7776 1247 c-61 -111 -233 -429 -383 -705 -150 -277 -273 -508 -273
-513 0 -5 97 -9 228 -9 l227 0 66 128 67 127 440 3 441 2 66 -130 66 -130 235
0 235 0 -58 108 c-32 60 -206 381 -388 715 l-330 606 -265 0 -265 0 -109 -202z
m522 -400 c73 -141 132 -260 132 -262 0 -3 -128 -5 -285 -5 l-286 0 143 279
c116 226 145 275 154 262 6 -9 70 -132 142 -274z"
      />
      <path
        d="M9340 735 l0 -715 215 0 215 0 0 250 0 250 495 0 495 0 0 155 0 155
-495 0 -495 0 0 145 0 145 520 0 520 0 0 165 0 165 -735 0 -735 0 0 -715z"
      />
      <path
        fill="o"
        d="M11690 1464 c-134 -5 -300 -24 -362 -40 -185 -47 -272 -140 -315
-336 -21 -95 -24 -578 -5 -678 25 -124 51 -187 102 -243 119 -130 267 -158
850 -158 536 0 691 24 816 126 54 45 107 149 125 247 19 101 19 605 0 706 -16
91 -70 200 -118 243 -71 62 -186 101 -348 118 -96 11 -603 21 -745 15z m585
-345 c126 -18 187 -71 202 -177 12 -85 8 -390 -7 -442 -15 -55 -72 -114 -125
-129 -75 -22 -287 -33 -490 -27 -289 8 -363 33 -402 133 -15 41 -18 78 -18
268 0 209 1 222 23 267 25 50 49 70 115 95 74 28 533 36 702 12z"
      />
      <path
        d="M13160 736 l0 -716 215 0 215 0 0 200 0 200 193 0 c105 -1 263 -5
349 -9 135 -7 163 -11 204 -32 27 -13 56 -35 66 -49 22 -31 38 -130 38 -232
l0 -78 210 0 210 0 0 178 c0 97 -5 197 -11 222 -19 84 -81 137 -182 155 l-37
7 72 37 c63 31 77 44 105 90 46 77 56 139 51 320 -6 176 -18 224 -74 289 -41
48 -113 86 -209 109 -61 14 -160 17 -742 20 l-673 4 0 -715z m1172 375 c53
-17 85 -60 93 -126 16 -129 -17 -213 -93 -235 -21 -6 -188 -13 -389 -17 l-353
-6 0 203 0 203 353 -6 c215 -3 366 -10 389 -16z"
      />
      <path
        d="M15130 735 l0 -715 210 0 210 0 2 522 3 522 166 -309 c92 -171 218
-406 280 -523 l114 -212 168 2 169 3 232 430 c127 237 252 467 276 513 42 77
45 80 51 55 4 -16 7 -247 8 -515 l1 -488 210 0 210 0 0 715 0 715 -322 -2
-323 -3 -237 -455 c-269 -515 -268 -513 -272 -508 -2 1 -99 183 -216 403 -117
220 -232 437 -257 483 l-44 82 -320 0 -319 0 0 -715z"
      />
      <path
        d="M17983 745 c-211 -387 -383 -709 -383 -715 0 -6 80 -10 228 -10 l228
0 64 130 64 130 443 0 443 0 65 -130 65 -130 236 0 235 0 -50 92 c-27 51 -202
372 -388 713 l-338 620 -265 3 -265 2 -382 -705z m815 60 c59 -116 109 -213
110 -217 2 -5 -125 -8 -283 -8 l-287 0 143 280 143 279 34 -62 c18 -34 81
-156 140 -272z"
      />
      <path
        d="M19410 1285 l0 -165 305 0 305 0 0 -550 0 -550 220 0 220 0 0 550 0
550 305 0 305 0 0 165 0 165 -830 0 -830 0 0 -165z"
      />
    </g>
  </svg>
</template>

<script>
function getStartingPosOfPath(path) {
  let value;
  const pathAttr = path.getAttribute("d").split(" ");
  if (pathAttr[0] === "M") {
    [, value] = pathAttr;
  } else {
    value = pathAttr
      .find(p => /^M[0-9]+$/.test(p)) // picks first
      .replace("M", "")
      .trim();
  }
  return parseInt(value, 10);
}

export default {
  name: "MfLogo",
  props: {
    color: {
      type: String,
      default: "#000000"
    },
    isShort: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isShort: {
      handler: "toggleShort"
    }
  },
  mounted() {
    this.children = Array.from(this.$refs.container.childNodes);

    setTimeout(() => {
      if (this.$refs.container) {
        this.$refs.container.classList.add("activated");
      }
    }, 500);
  },
  methods: {
    toggleShort() {
      const full = "MEDIAFORMAT";
      const shortIndexes = "MF".split("").map(letter => full.indexOf(letter));

      if (this.isShort) {
        full.split("").forEach((letter, letterIndex) => {
          const $el = this.children[letterIndex];
          if (shortIndexes.includes(letterIndex)) {
            const newIndex = shortIndexes.findIndex(i => i === letterIndex);
            if (newIndex !== letterIndex) {
              // we only need to move when the index of the letter changes
              const startingPointOld = getStartingPosOfPath(
                this.children[newIndex]
              );
              const currentPos = getStartingPosOfPath($el);

              $el.style.transform = `translate(${startingPointOld -
                currentPos}px, 0)`;
            }
          } else {
            $el.style.opacity = "0";
            const pos = getStartingPosOfPath($el);
            $el.style.transform = `translate(-${pos}px, 0)`;
          }
        });
      } else {
        // resetting elements back to their original position
        this.children.forEach($el => {
          const el = $el;
          el.style.opacity = null;
          el.style.transform = ``;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.svg-container {
  path {
    opacity: 1;
  }

  &.activated {
    path {
      transition: opacity 0.5s ease-in, transform 0.5s ease-in-out;
    }
  }
}
</style>
